import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import React from "react"
import styled from "styled-components"
import { TextBold } from "../Texts"
export const AboutContainer = styled.section`
  display: grid;
  grid-template-columns: 75% 25%;
  gap: 1rem;

  animation: AboutAnimation 1.5s cubic-bezier(0.2, 0.82, 0.2, 1);
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @keyframes AboutAnimation {
    0% {
      opacity: 0;
      transform: translateX(-6rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`
export const Aside = styled.aside`
  margin-top: 5rem;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`
const Bold = ({ children }) => <TextBold>{children}</TextBold>
const Text = ({ children }) => <p>{children}</p>

export const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <>
          <h2>Embedded Asset</h2>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      )
    },
  },
}
