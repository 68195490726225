import styled from "styled-components"

export const SkillContainer = styled.article`
  margin-bottom: 2rem;
`

export const TitleSkills = styled.h2`
  margin-top: 1rem;
`

export const ListSkills = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  list-style: circle;
`

export const ListSkillItem = styled.li`
  font-weight: 600;
`
