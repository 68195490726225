import { graphql, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"
import { Button } from "../components/Buttons"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import Skills from "../components/Skills"
import Social from "../components/Social"
import {
  AboutContainer,
  Aside,
  options,
} from "../components/styled-pages/about"
import { H1About, TextAboutMe } from "../components/Texts"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulAbout {
        edges {
          node {
            title
            description {
              raw
            }
            skills {
              skills
            }
          }
        }
      }
    }
  `)

  const about = data.allContentfulAbout.edges[0].node

  return (
    <Layout>
      <Seo title="Acerca de Alejandro" />
      <AboutContainer>
        <TextAboutMe>
          <H1About>{about.title}</H1About>
          {renderRichText(about.description, options)}
          <Skills data={about.skills.skills} />
        </TextAboutMe>
        <Aside>
          <Social />
          <Button text="Proyectos" url="/projects" />
        </Aside>
      </AboutContainer>
    </Layout>
  )
}

export default AboutPage
