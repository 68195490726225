import styled from "styled-components"

export const SocialGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const SocialLink = styled.a`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  p {
    font-weight: 600;
    word-break: break-word;
  }
`

export const SocialIcon = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 0.5rem;
`

export const SvgIcon = styled.svg`
  width: 36px;
  height: 36px;
  margin-right: 0.5rem;
  margin-bottom: 1.45rem;
`
