import React from "react"
import {
  ListSkillItem,
  ListSkills,
  SkillContainer,
  TitleSkills,
} from "./styled"

const Skills = ({ data }) => {
  return (
    <SkillContainer>
      <TitleSkills>Skills</TitleSkills>
      <ListSkills>
        {data.map(skill => (
          <ListSkillItem key={skill}>{skill}</ListSkillItem>
        ))}
      </ListSkills>
    </SkillContainer>
  )
}

export default Skills
