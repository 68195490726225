import styled from "styled-components"

export const H1About = styled.h1`
  color: transparent;
  font-size: 4vmin;
  line-height: 1;
  letter-spacing: 2px;
  text-align: center;
  margin: 1rem 0 3rem 0;
  -webkit-text-stroke: 1px ${({ theme }) => theme.text};
  @media (max-width: 800px) {
    font-size: 6vmin;
  }
`

export const TitlePageProjects = styled(H1About)`
  font-size: 7vmin;
  text-transform: uppercase;
  place-self: center;
  transform: rotate(90deg);
  margin-top: -40px;
  margin-left: -50px;
  position: absolute;
  left: 0;
  top: 15rem;
  @media (max-width: 1220px) {
    position: relative;
    top: 0;
    margin: 0;
    transform: rotate(0deg);
    font-size: 8vmin;
  }
`

export const TextAboutMe = styled.article`
  line-height: 1.6;
  p {
    margin-bottom: 1rem;
  }
`
export const TextBold = styled.span`
  font-weight: bold;
`
export const Emoji = styled.span`
  font-size: 1.5rem;
  padding-right: 0.5rem;
`
